<template>
  <!-- eslint-disable max-len -->
  <svg
    viewBox="0 0 24 24">
    <path
      d="M21.124,0.003H2.854c-1.58,0-2.865,1.285-2.865,2.865v18.271c0,1.579,1.285,2.864,2.865,2.864h18.27
      c1.58,0,2.865-1.285,2.865-2.864V2.868C23.989,1.288,22.704,0.003,21.124,0.003z M2.854,1.003h18.27
      c1.028,0,1.865,0.836,1.865,1.865v8.934l-3.668-3.109c0.099-0.222,0.156-0.467,0.156-0.725c0-0.989-0.805-1.793-1.794-1.793
      c-0.988,0-1.793,0.805-1.793,1.793c0,0.471,0.187,0.898,0.485,1.218l-3.902,6.547c-0.144-0.037-0.293-0.064-0.448-0.064
      c-0.369,0-0.713,0.113-0.998,0.305l-3.022-2.533c0.098-0.221,0.154-0.464,0.154-0.721c0-0.989-0.805-1.793-1.793-1.793
      s-1.793,0.804-1.793,1.793c0,0.256,0.056,0.499,0.153,0.72L0.99,16.558V2.868C0.989,1.84,1.826,1.003,2.854,1.003z M16.891,7.968
      c0-0.438,0.355-0.793,0.793-0.793s0.794,0.356,0.794,0.793s-0.356,0.793-0.794,0.793S16.891,8.406,16.891,7.968z M12.025,16.67
      c0.438,0,0.793,0.355,0.793,0.793s-0.356,0.793-0.793,0.793s-0.793-0.355-0.793-0.793S11.587,16.67,12.025,16.67z M5.572,12.72
      c0-0.437,0.356-0.793,0.793-0.793s0.793,0.356,0.793,0.793c0,0.438-0.356,0.793-0.793,0.793S5.572,13.157,5.572,12.72z
      M21.124,23.003H2.854c-1.028,0-1.865-0.836-1.865-1.864V17.86l4.377-3.652c0.286,0.193,0.63,0.306,1,0.306
      c0.369,0,0.713-0.113,0.998-0.305l3.022,2.533c-0.098,0.221-0.154,0.464-0.154,0.721c0,0.988,0.805,1.793,1.793,1.793
      s1.793-0.805,1.793-1.793c0-0.471-0.187-0.898-0.486-1.218l3.902-6.547c0.144,0.038,0.293,0.064,0.449,0.064
      c0.368,0,0.71-0.112,0.995-0.303l4.311,3.653v8.026C22.989,22.167,22.152,23.003,21.124,23.003z"/>
  </svg>
</template>
